import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import {
  loadStripe,
  Stripe,
  StripeCardNumberElement,
  StripeElements,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { FormEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { CardNumberElement } from "@stripe/react-stripe-js";

export const configJSON = require("./config");

export interface Props {
  onCloseDialog: () => void;
  onSuccess: () => void;
  onCancelSubscription: (isImmediate: boolean) => void;
}

interface ICardItem {
  payment_id: string;
  card_number: string;
  card_type: string;
  default: boolean;
}

export interface S {
  isLoading: boolean;
  cardListItems: ICardItem[];
  isOpenCardListView: boolean;
  selectedCard: string;
  errorMsg: string;
  stripeAction: string;
  cancellationOptions: string;
}

export interface SS {}

export default class EditStripeSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  showCardListApiId: string = "";
  removeCardApiId: string = "";
  addNewCardApiId: string = "";
  updateDefaultCardApiId: string = "";
  cancelSubscriptionMidMonthApiId: string = "";
  cancelSubscriptionAndLogoutApiId: string = "";
  logoutApiId: string = "";
  stripePromise = loadStripe(configJSON.stripeKey);

  options: StripeElementsOptions = {
    mode: configJSON.optionMode,
    amount: configJSON.optionAmount,
    currency: configJSON.optionCurrency,
    paymentMethodCreation: configJSON.optionPaymentMethod,
    appearance: {},
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
      cardListItems: [],
      isOpenCardListView: true,
      selectedCard: "",
      errorMsg: "",
      stripeAction: "edit",
      cancellationOptions: "mid-month",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.showAllCardList();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.showCardListApiId) {
      this.toggleLoader(false);
      if (responseJson && responseJson.attached_cards) {
        const defaultCard = responseJson.attached_cards.find(
          (item: ICardItem) => item.default
        );

        this.setState({
          cardListItems: responseJson.attached_cards,
          selectedCard: defaultCard?.payment_id || "",
        });
      }
    }

    if (apiRequestCallId === this.updateDefaultCardApiId) {
      this.setUpdateDefaultCardResponse(responseJson);
    }

    if (apiRequestCallId === this.removeCardApiId) {
      this.setRemoveCardResponse(responseJson);
    }

    if (apiRequestCallId === this.addNewCardApiId) {
      this.setAddNewCardResponse(responseJson);
    }

    if (
      apiRequestCallId === this.cancelSubscriptionAndLogoutApiId ||
      apiRequestCallId === this.cancelSubscriptionMidMonthApiId
    ) {
      this.setCancelSubscriptionResponse(apiRequestCallId, responseJson);
    }
  }

  setCancelSubscriptionResponse = (
    apiRequestCallId: string,
    responseJson: { message?: string }
  ) => {
    if (apiRequestCallId === this.cancelSubscriptionAndLogoutApiId) {
      this.toggleLoader(false);
      if (
        responseJson &&
        responseJson?.message === configJSON.cancelImmediatelySuccessMsg
      ) {
        this.handleLogout();
        this.props.onCancelSubscription(true);
      }
    }
    if (apiRequestCallId === this.cancelSubscriptionMidMonthApiId) {
      this.toggleLoader(false);
      if (
        responseJson &&
        responseJson?.message === configJSON.cancelAtMidMonthSuccessMsg
      ) {
        this.props.onCancelSubscription(false);
      }
    }
  };

  setUpdateDefaultCardResponse = (responseJson: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseJson &&
      responseJson?.message === configJSON.updateCardSuccessMsg
    ) {
      this.props.onSuccess();
    }
  };

  setRemoveCardResponse = (responseJson: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseJson &&
      responseJson?.message === configJSON.deleteCardSuccessMsg
    ) {
      this.showAllCardList();
    }
  };

  setAddNewCardResponse = (responseJson: {
    message?: string;
    error?: string;
  }) => {
    this.toggleLoader(false);
    if (
      responseJson &&
      responseJson?.message === configJSON.addCardSuccessMsg
    ) {
      this.showAllCardList();
      this.toggleShowCardView();
    } else if (responseJson) {
      const errorMsg = responseJson?.message || responseJson?.error;
      this.setState({ errorMsg: errorMsg as string }, () => {
        setTimeout(() => {
          this.setState({ errorMsg: "" });
        }, 3000);
      });
    }
  };

  onCloseStripeGateway = () => {
    this.props.onCloseDialog();
  };

  toggleLoader = (isLoading: boolean) => {
    this.setState({ isLoading: isLoading });
  };

  toggleShowCardView = () => {
    this.setState({ isOpenCardListView: !this.state.isOpenCardListView });
  };

  onCancelSubscription = () => {
    if (this.state.cancellationOptions === "terminate") {
      this.cancelSubscriptionAndLogout();
    } else {
      this.cancelSubscriptionAtMidMonth();
    }
  };

  handleChangeDefaultCard = (
    event: React.ChangeEvent<HTMLInputElement>,
    cardId: string
  ) => {
    this.setState({ selectedCard: cardId });
  };

  handleChangeStripeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ stripeAction: (event.target as HTMLInputElement).value });
  };

  handleChangeCancelAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      cancellationOptions: (event.target as HTMLInputElement).value,
    });
  };

  handleSubmit = async (
    event: FormEvent,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    this.toggleLoader(true);
    event.preventDefault();

    if (!stripe || !elements) {
      this.toggleLoader(false);
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      this.toggleLoader(false);
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(
      cardElement as StripeCardNumberElement
    );
    if (error && error.message) {
      this.setState({ errorMsg: error.message, isLoading: false }, () => {
        setTimeout(() => {
          this.setState({ errorMsg: "" });
        }, 3000);
      });
    }
    if (token && token.id) {
      this.addNewCard(token.id);
    }
  };

  showAllCardList = async () => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const showCardListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showCardListApiId = showCardListRequestMsg.messageId;

    showCardListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showCardListApiEndPoint
    );

    showCardListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    showCardListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(showCardListRequestMsg.id, showCardListRequestMsg);
  };

  updateDefaultCardForPayment = async () => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const updateDefaultCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateDefaultCardApiId = updateDefaultCardRequestMsg.messageId;

    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setDefaultCardApiEndPoint
    );

    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPaymentMethod
    );
    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        card_token: this.state.selectedCard,
      })
    );

    runEngine.sendMessage(
      updateDefaultCardRequestMsg.id,
      updateDefaultCardRequestMsg
    );
  };

  addNewCard = async (cardToken: string) => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const addNewCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addNewCardApiId = addNewCardRequestMsg.messageId;

    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNewCardApiEndPoint
    );

    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPaymentMethod
    );
    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        card_token: cardToken,
      })
    );

    runEngine.sendMessage(addNewCardRequestMsg.id, addNewCardRequestMsg);
  };

  deleteCard = async (cardToken: string) => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const deleteCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeCardApiId = deleteCardRequestMsg.messageId;

    deleteCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCardApiEndPoint + cardToken
    );

    deleteCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(deleteCardRequestMsg.id, deleteCardRequestMsg);
  };

  cancelSubscriptionAtMidMonth = async () => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const cancelSubscriptionMidMonthRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelSubscriptionMidMonthApiId =
      cancelSubscriptionMidMonthRequestMsg.messageId;

    cancelSubscriptionMidMonthRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionMidMonthApiEndPoint
    );

    cancelSubscriptionMidMonthRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cancelSubscriptionMidMonthRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(
      cancelSubscriptionMidMonthRequestMsg.id,
      cancelSubscriptionMidMonthRequestMsg
    );
  };

  cancelSubscriptionAndLogout = async () => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const cancelSubscriptionAndLogoutRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelSubscriptionAndLogoutApiId =
      cancelSubscriptionAndLogoutRequestMsg.messageId;

    cancelSubscriptionAndLogoutRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelAndLogoutApiEndPoint
    );

    cancelSubscriptionAndLogoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cancelSubscriptionAndLogoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(
      cancelSubscriptionAndLogoutRequestMsg.id,
      cancelSubscriptionAndLogoutRequestMsg
    );
  };

  handleLogout = async () => {
    const token = await getStorageData("token");
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    const handleLogoutRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logoutApiId = handleLogoutRequestMsg.messageId;

    handleLogoutRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutApiEndPoint
    );

    handleLogoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleLogoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ token: token })
    );
    handleLogoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(handleLogoutRequestMsg.id, handleLogoutRequestMsg);
  };
}
